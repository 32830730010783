

































































































































































/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component } from 'vue-property-decorator';
import WidgetMixins from '../../mixins/WidgetMixins';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, numeric, max, required } from 'vee-validate/dist/rules';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

extend('email', {
  ...email,
  message: 'Email is not valid',
});
extend('required', {
  ...required,
  message: 'Field is required',
});
extend('numeric', {
  ...numeric,
  message: 'Invalid phone number provided',
});
extend('max', {
  ...max,
  message: 'Phone number cannot be more than 10',
});

const authModule = namespace('auth');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class Login extends mixins(WidgetMixins) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @authModule.Action('registerAsDeveloper') actionRegister: any;
  name: string = '';
  email: string = '';
  phone: string = '';
  password: string = '';
  acceptPrivacyPolicy: boolean = true;
  confirmPassword: string = '';
  bg: string = require('@/assets/images/blob-scene-haikei.svg');
  show: boolean = false;

  register(): void {
    if (this.password.trim() !== this.confirmPassword.trim()) {
      this.$store.dispatch('snackBarMessage', "Password doesn't match", {
        root: true,
      });
      this.$store.dispatch('snackBarVisibility', true, { root: true });
      return;
    }
    let payload = {
      name: this.name,
      email: this.email,
      phone: this.phone,
      password: this.password,
      isBusiness: false,
      acceptPrivacyPolicy: this.acceptPrivacyPolicy,
    };
    this.actionRegister(payload);
  }
}
