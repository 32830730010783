var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"height":"100%"},attrs:{"id":"signIn"}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 full-width flex-wrap flex-shrink-0 align-content-center justify-center"},[_c('div',{staticClass:"py-3 px-3",staticStyle:{"position":"absolute","left":"2%","top":"2%"}},[_c('img',{staticClass:"cursor-pointer animate__animated animate__fadeIn",staticStyle:{"width":"120px"},attrs:{"src":require("@/assets/logo.jpeg"),"alt":"MSys"},on:{"click":function($event){return _vm.$router.push({ name: 'accounts.signin' })}}})]),_c('div',{staticClass:"rounded-sm ma-3 pl-4 pr-4 pb-3 animate__animated animate__fadeIn",staticStyle:{"border-radius":"8px !important","max-width":"500px"}},[_c('h2',{staticClass:"ft text-center text-center font-weight-bold"},[_vm._v(" Get started for free. ")]),_c('p',{staticClass:"ft font-weight-medium text-sm text-center"},[_vm._v(" Your wider reach of customers begins here. ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)}}},[_c('div',{staticClass:"d-flex flex-row flex-wrap flex-grow-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1",attrs:{"name":"fullName","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft text-sm mt-2 mr-lg-2 mr-md-2 mr-sm-2",attrs:{"type":"text","outlined":"","placeholder":"Jane Doe","label":"Full Name","error-messages":errors[0]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1",attrs:{"name":"email","rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mt-2 text-sm",attrs:{"type":"text","placeholder":"example@example.com","outlined":"","label":"Email Address","error-messages":errors[0]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}],null,true)})],1),_c('ValidationProvider',{attrs:{"name":"phone","rules":"required|numeric|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mt-2 text-sm",attrs:{"type":"text","outlined":"","placeholder":"23345984983","label":"Phone Number","error-messages":errors[0]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{ref:"password",staticClass:"ft mt-2 text-sm",attrs:{"append-icon":_vm.show ? 'visibility_off' : 'visibility',"type":_vm.show ? 'text' : 'password',"outlined":"","label":"Password","error-messages":errors[0]},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{ref:"password",staticClass:"ft mt-2 text-sm",attrs:{"type":_vm.show ? 'text' : 'password',"outlined":"","label":"Confirm Password","error-messages":errors[0]},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)]}}],null,true)}),_c('v-card-actions',{staticClass:"justify-end mt-n3 pl-0 pr-0 pb-2"},[_c('v-btn',{attrs:{"depressed":"","type":"submit","loading":_vm.isLoading,"color":"primary","large":"","block":""}},[_c('span',{staticClass:"ft font-weight-medium text-capitalize text-sm"},[_vm._v("Get started")])])],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-center py-4"},[_c('router-link',{attrs:{"to":"/signin"}},[_c('span',{staticClass:"ft font-weight-medium text-sm"},[_vm._v("Do you have an account?")])])],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-center"},[_c('span',{staticClass:"ft font-weight-medium text-sm text-center"},[_vm._v(" Signing up for a Managed Systems Consult means you agree to the "),_c('router-link',{attrs:{"to":"/privacy-policy"}},[_c('span',{staticClass:"ft"},[_vm._v("Privacy Policy")])]),_vm._v(" and "),_c('router-link',{attrs:{"to":"/terms"}},[_c('span',{staticClass:"ft"},[_vm._v("Terms of Service")])])],1)])],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }